button.menu-button-mobile.m-l-1.btn.btn-primary,
button.menu-button-mobile.m-l-1.btn.btn-primary:hover {
  background-color: transparent !important;
  color: black !important;
  border: none;
  font-size: 1.5rem;
}

.primary-button {
  padding: 0.5rem 2rem !important;
  background-color: transparent !important;
  color: inherit !important;
  float: right;
  margin-top: 2rem !important;
}

.f-g-c {
  fill: greenyellow !important;
}

.v-h {
  visibility: hidden !important;
}

.w-1_25 {
  width: 1.25rem !important;
}

.LazyLoad {
  opacity: 0 !important;
  transition: all 1s ease-in-out !important;
}

.j-c-f-e {
  justify-content: flex-end !important;
}

.is-visible {
  opacity: 1 !important;
}

.w-1_75 {
  width: 1.75rem !important;
}

.bt-0 {
  bottom: 0 !important;
}

.left-0 {
  left: 0 !important;
}

.m-t-_2 {
  margin-top: -2rem !important;
}

img.w3a-header__logo {
  display: none;
}

.red_f_clr {
  fill: red !important;
}

.f-s-18px {
  font-size: 18px !important;
}

.modal-content {
  border: none !important;
  box-shadow: 0px 0px 5px #0088ff !important;
  border-radius: 1rem !important;
  padding: 2rem !important;
}

.apexcharts-text tspan {
  fill: var(--color) !important;
}

.min-h-90vh {
  min-height: 90vh !important;
}

.c13 {
  // background-color: #ffffff !important;
}

// .c25 {
//   // max-width: 451.4pt;
//   padding: 2rem !important;

// }
.c0 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: left;
  // font-size: 1.5rem !important;
  margin: 2rem -0rem !important;
}
.c7 {
  color: #000000;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 1.25rem;
  // font-family: "Arial";
  font-style: normal;
}

div#apexchartszoomable-chart {
  padding-top: 2rem !important;
}

div#w3a-modal {
  z-index: 99 !important;
}

tr,
td,
th,
tbody,
table,
thead {
  background-color: transparent !important;
  color: var(--color) !important;
  box-shadow: none !important;
}

.f-w-i {
  flex-wrap: inherit !important;
}

.b-r_1 {
  border-right: 1px solid #707070 !important;
}

.b-s-n {
  box-shadow: none !important;
}

.signup:hover {
  background-color: transparent !important;
  border: 1px solid var(--blue_clr) !important;
}

.max-w-50 {
  max-width: 50rem !important;
}

.border_clr_w {
  border-color: rgb(150, 150, 150) !important;
}

textarea {
  outline: none !important;
  color: var(--color);
}

.submit:hover {
  background-color: #0066be !important;
}

.w-s-n-w {
  white-space: nowrap !important;
}

.red_clr {
  color: red !important;
}

.l-b-a {
  line-break: anywhere !important;
}

.r_f_clr {
  fill: red !important;
}

// .recharts-default-tooltip{
//     background-color: black !important;
// }

.w-s-n-w {
  white-space: nowrap !important;
}

@media screen and (min-width: 900px) {
  .m-q-d-f {
    display: flex !important;
  }

  .m-q-m-r-1 {
    margin-right: 1rem !important;
  }
}

.max-w-50rem {
  max-width: 50rem !important;
}

.apexcharts-theme-light .apexcharts-menu-item:hover {
  background-color: rgb(41, 41, 41) !important;
}

.dropdown-toggle::after {
  display: none !important;
}

.fear-and-greed.light.horizontal {
  box-shadow: none !important;
}

.widget-container {
  box-shadow: none !important; /* or box-shadow: 0; to completely remove the shadow */
}

.p-f {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding-bottom: 1rem !important;
}
