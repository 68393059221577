// :root {
//     --background-color: #05021c;
//     --color: white;
//     --dark_blue: #05021c;
//     --gray_clr: #707070;
//     --blue_clr: #0088FF;
//     --gray_fill_color: #95989A;
//     --border_clr_blue: #0088FF;
//     --green: #59ff8b;
//     --red: #FF0000;
//     --dark_violet: #161C31;
// }

.light-theme {
  --background-color: #ffffff;
  --color: black;
  --dark_blue: white;
  --gray_clr: #707070;
  --blue_clr: #0088ff;
  --gray_fill_color: #95989a;
  --border_clr_blue: #0088ff;
  --green: #28cc8f;
  --red: #ff0000;
  --dark_violet: #ebebeb;
  --box-color: #ebebeb;
  --box-color_hover: #d1d1d1;
  --box-shadow: #e6e6e6;
  --disqus: #ebebeb;
}

.dark-theme {
  --background-color: #05021c;
  --color: white;
  --dark_blue: #05021c;
  --gray_clr: #707070;
  --blue_clr: #0088ff;
  --gray_fill_color: #95989a;
  --border_clr_blue: #0088ff;
  --green: #59ff8b;
  --red: #ff0000;
  --dark_violet: #161c31;
  --box-color: #313033;
  --box-color_hover: rgb(85, 85, 85);
  --box-shadow: #666666;
  --disqus: black;
}

.apexcharts-tooltip.apexcharts-theme-light,
.apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title,
.apexcharts-menu {
  background-color: var(--background-color) !important;
}

.bg_g_clr_btn:hover {
  background-color: var(--box-color_hover) !important;
}

html,
body,
input,
select,
#dropdown,
.modal-content,
.pa-1.offcanvas.offcanvas-start.show,
svg,
.dropdown-menu.show,
a,
a:hover {
  background-color: var(--background-color) !important;
  color: var(--color) !important;
  fill: var(--color) !important;
  border-color: var(--color) !important;
}

// .fear-and-greed {
//     box-shadow: none !important;
// }

.bg_violet {
  background-color: var(--dark_violet) !important;
}

.h-40 {
  height: 40rem !important;
}

.h-60 {
  height: 60rem !important;
}

.pa-5px {
  padding: 0.5rem 1rem !important;
}

.pa-5rem {
  padding: 0.5rem !important;
}

a,
a:hover {
  background-color: transparent !important;
}

a:hover {
  transform: scale(1) !important;
  color: var(--color) !important;
  /* Scale the text on hover (1.2 times its original size) */
}

.dropdown-item:hover,
.dropdown-item:focus {
  background-color: var(--box-color) !important;
}

.bg_g_clr {
  background-color: var(--box-color) !important;
}

.box_shadow {
  box-shadow: 0px 0px 5px var(--box-shadow) !important;
}

.dropdown-menu.show {
  border: none !important;
  // background-color: #373549 !important;
}

.bg_d_b_clr {
  background-color: var(--dark_blue) !important;
}

.g_clr {
  color: var(--gray_clr) !important;
}

.b_clr {
  color: var(--blue_clr) !important;
}

.g_f_clr {
  fill: var(--gray_fill_color) !important;
}

.b_clr_blue {
  border-color: var(--border_clr_blue) !important;
}

.b_clr_gray {
  border-color: var(--gray_clr) !important;
}

.f_w {
  fill: rgb(223, 223, 223) !important;
}

.bg_black_clr {
  background-color: var(--disqus) !important;
}

.bg_green_clr {
  background-color: var(--green) !important;
}

.green_clr {
  color: var(--green) !important;
}

.green_f_clr {
  fill: var(--green) !important;
}

.red_clr {
  color: var(--red) !important;
}

.bg_red_clr {
  background-color: var(--red) !important;
}

.bg_b_clr {
  background-color: var(--blue_clr) !important;
}

// body {
//     background-color: #05021c !important;
//     color: rgb(255, 255, 255) !important;
// }

.clr {
  color: var(--color) !important;
}

.bg_clr {
  background-color: var(--background-color) !important;
}

.login:hover {
  background-color: #0088ff !important;
  color: white !important;
}
